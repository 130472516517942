<template>
  <mobile-screen :header="true" screen-class="change-password-screen icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: 'r_manage-users' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("manage-users", "edit-user", "edit-user") }}
        </div>
        <template v-slot:right>
          <button
            @click="initSubmit"
            v-if="user && user.data && canItem(user.data, 'update')"
          >
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      v-if="user && user.data"
      @submit="submitForm"
      :key="$route.params.user_id || 0"
      ref="editUserForm"
      v-slot="{ errors }"
      class="form"
      :initial-values="user && user.data && { ...user.data }"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.email }"
        >
          <Popper
            v-if="user.data.azure_external_id"
            hover
            class="popper-warning w-100"
            :content="displayLabelName('profile.profile.added-externally')"
          >
            <section class="disabled">
              <span class="error-message" v-if="errors.email">
                {{ errors.email }}
              </span>
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label class="clebex-section-input-label" for="emailId"
                    >{{
                      displayLabelName("manage-users", "edit-user", "email")
                    }}
                    <span>*</span></label
                  >
                  <Field
                    name="email"
                    as="input"
                    type="email"
                    rules="required|email"
                    id="emailId"
                    :disabled="submitting || !canItem(user.data, 'update')"
                  />
                </div>
              </div>
            </section>
          </Popper>
          <section v-else>
            <span class="error-message" v-if="errors.email">
              {{ errors.email }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="emailId"
                  >{{ displayLabelName("manage-users", "edit-user", "email") }}
                  <span>*</span></label
                >
                <Field
                  name="email"
                  as="input"
                  type="email"
                  rules="required|email"
                  id="emailId"
                  :disabled="submitting || !canItem(user.data, 'update')"
                />
              </div>
            </div>
          </section>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.first_name }"
        >
          <Popper
            v-if="user.data.azure_external_id"
            hover
            class="popper-warning w-100"
            :content="displayLabelName('profile.profile.added-externally')"
          >
            <section class="disabled">
              <span class="error-message" v-if="errors.first_name">
                {{ errors.first_name }}
              </span>
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label class="clebex-section-input-label" for="firstNameId"
                    >{{
                      displayLabelName(
                        "manage-users",
                        "edit-user",
                        "first-name"
                      )
                    }}
                    <span>*</span></label
                  >
                  <Field
                    name="first_name"
                    as="input"
                    type="text"
                    rules="required"
                    id="firstNameId"
                    :disabled="submitting || !canItem(user.data, 'update')"
                  />
                </div>
              </div>
            </section>
          </Popper>
          <section v-else>
            <span class="error-message" v-if="errors.first_name">
              {{ errors.first_name }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="firstNameId"
                  >{{
                    displayLabelName("manage-users", "edit-user", "first-name")
                  }}
                  <span>*</span></label
                >
                <Field
                  name="first_name"
                  as="input"
                  type="text"
                  rules="required"
                  id="firstNameId"
                  :disabled="submitting || !canItem(user.data, 'update')"
                />
              </div>
            </div>
          </section>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.middleName"
        >
          <Popper
            v-if="user.data.azure_external_id"
            hover
            class="popper-warning w-100"
            :content="displayLabelName('profile.profile.added-externally')"
          >
            <section class="disabled">
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label
                    class="clebex-section-input-label"
                    for="middleNameId"
                    >{{
                      displayLabelName(
                        "manage-users",
                        "edit-user",
                        "middle-name"
                      )
                    }}</label
                  >
                  <Field
                    name="middle_name"
                    as="input"
                    type="text"
                    id="middleNameId"
                    :disabled="submitting || !canItem(user.data, 'update')"
                  />
                </div>
              </div>
            </section>
          </Popper>
          <section v-else>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="middleNameId">{{
                  displayLabelName("manage-users", "edit-user", "middle-name")
                }}</label>
                <Field
                  name="middle_name"
                  as="input"
                  type="text"
                  id="middleNameId"
                  :disabled="submitting || !canItem(user.data, 'update')"
                />
              </div>
            </div>
          </section>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.last_name }"
        >
          <Popper
            v-if="user.data.azure_external_id"
            hover
            class="popper-warning w-100"
            :content="displayLabelName('profile.profile.added-externally')"
          >
            <section class="disabled">
              <span class="error-message" v-if="errors.last_name">
                {{ errors.last_name }}
              </span>
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label class="clebex-section-input-label" for="lastNameId"
                    >{{
                      displayLabelName("manage-users", "edit-user", "last-name")
                    }}
                    <span>*</span></label
                  >
                  <Field
                    name="last_name"
                    as="input"
                    type="text"
                    id="lastNameId"
                    rules="required"
                    :disabled="submitting || !canItem(user.data, 'update')"
                  />
                </div>
              </div>
            </section>
          </Popper>
          <section v-else>
            <span class="error-message" v-if="errors.last_name">
              {{ errors.last_name }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="lastNameId"
                  >{{
                    displayLabelName("manage-users", "edit-user", "last-name")
                  }}
                  <span>*</span></label
                >
                <Field
                  name="last_name"
                  as="input"
                  type="text"
                  id="lastNameId"
                  rules="required"
                  :disabled="submitting || !canItem(user.data, 'update')"
                />
              </div>
            </div>
          </section>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.phone }"
        >
          <span class="error-message" v-if="errors.phone">
            {{ errors.phone }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="phoneId"
                >{{ displayLabelName("manage-users", "edit-user", "phone") }}
                <span>*</span></label
              >
              <Field
                name="phone"
                as="input"
                type="tel"
                rules="required"
                :disabled="submitting || !canItem(user.data, 'update')"
                id="phoneId"
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.username }"
        >
          <Popper
            v-if="user.data.azure_external_id"
            hover
            class="popper-warning w-100"
            :content="displayLabelName('profile.profile.added-externally')"
          >
            <section class="disabled">
              <span class="error-message" v-if="errors.username">
                {{ errors.username }}
              </span>
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label class="clebex-section-input-label" for="usernameId"
                    >{{
                      displayLabelName("manage-users", "edit-user", "username")
                    }}
                    <span>*</span></label
                  >
                  <Field
                    name="username"
                    as="input"
                    type="text"
                    id="usernameId"
                    rules="required"
                    :disabled="submitting || !canItem(user.data, 'update')"
                  />
                </div>
              </div>
            </section>
          </Popper>
          <section v-else>
            <span class="error-message" v-if="errors.username">
              {{ errors.username }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="usernameId"
                  >{{
                    displayLabelName("manage-users", "edit-user", "username")
                  }}
                  <span>*</span></label
                >
                <Field
                  name="username"
                  as="input"
                  type="text"
                  id="usernameId"
                  rules="required"
                  :disabled="submitting || !canItem(user.data, 'update')"
                />
              </div>
            </div>
          </section>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.city"
        >
          <Popper
            v-if="user.data.azure_external_id"
            hover
            class="popper-warning w-100"
            :content="displayLabelName('profile.profile.added-externally')"
          >
            <section class="disabled">
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label class="clebex-section-input-label" for="cityId">{{
                    displayLabelName("manage-users", "edit-user", "city")
                  }}</label>
                  <Field
                    name="city"
                    as="input"
                    type="text"
                    id="cityId"
                    v-model="user.data.city"
                  />
                </div>
              </div>
            </section>
          </Popper>
          <section v-else>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="cityId">{{
                  displayLabelName("manage-users", "edit-user", "city")
                }}</label>
                <Field
                  name="city"
                  as="input"
                  type="text"
                  id="cityId"
                  v-model="user.data.city"
                />
              </div>
            </div>
          </section>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.company"
        >
          <Popper
            v-if="user.data.azure_external_id"
            hover
            class="popper-warning w-100"
            :content="displayLabelName('profile.profile.added-externally')"
          >
            <section class="disabled">
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label class="clebex-section-input-label" for="companyId">{{
                    displayLabelName("manage-users", "edit-user", "company")
                  }}</label>
                  <Field
                    name="company"
                    as="input"
                    type="text"
                    id="companyId"
                    v-model="user.data.company"
                  />
                </div>
              </div>
            </section>
          </Popper>
          <section v-else>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="companyId">{{
                  displayLabelName("manage-users", "edit-user", "company")
                }}</label>
                <Field
                  name="company"
                  as="input"
                  type="text"
                  id="companyId"
                  v-model="user.data.company"
                />
              </div>
            </div>
          </section>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.department"
        >
          <Popper
            v-if="user.data.azure_external_id"
            hover
            class="popper-warning w-100"
            :content="displayLabelName('profile.profile.added-externally')"
          >
            <section class="disabled">
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label
                    class="clebex-section-input-label"
                    for="departmentId"
                    >{{
                      displayLabelName(
                        "manage-users",
                        "edit-user",
                        "department"
                      )
                    }}</label
                  >
                  <Field
                    name="department"
                    as="input"
                    type="text"
                    id="departmentId"
                    v-model="user.data.department"
                  />
                </div>
              </div>
            </section>
          </Popper>
          <section v-else>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="departmentId">{{
                  displayLabelName("manage-users", "edit-user", "department")
                }}</label>
                <Field
                  name="department"
                  as="input"
                  type="text"
                  id="departmentId"
                  v-model="user.data.department"
                />
              </div>
            </div>
          </section>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.passport"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="passportId">{{
                displayLabelName("manage-users", "edit-user", "passport")
              }}</label>
              <Field
                name="passport"
                as="input"
                type="text"
                id="passportId"
                v-model="user.data.passport"
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.licensePlate"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="licensePlateId">{{
                displayLabelName("manage-users", "edit-user", "license-plate")
              }}</label>
              <Field
                name="license_plate"
                as="input"
                type="text"
                id="licensePlateId"
                v-model="user.data.license_plate"
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.costCode"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="costCodeId">{{
                displayLabelName("manage-users", "edit-user", "cost-code")
              }}</label>
              <Field
                name="cost_code"
                as="input"
                type="text"
                id="costCodeId"
                v-model="user.data.cost_code"
              />
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.projectCostCode"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label
                class="clebex-section-input-label"
                for="projectCostCodeId"
                >{{
                  displayLabelName(
                    "manage-users",
                    "edit-user",
                    "project-cost-code"
                  )
                }}</label
              >
              <Field
                name="project_cost_code"
                as="input"
                type="text"
                id="projectCostCodeId"
                v-model="user.data.project_cost_code"
              />
            </div>
          </div>
        </li>
      </ul>
      <ul class="clebex-item-section">
        <!---------------------------------->
        <!-- LANGUAGE - START -->
        <!---------------------------------->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.language_id }"
          v-if="userFormFields.language"
        >
          <span class="error-message" v-if="errors.language_id">
            {{ errors.language_id }}
          </span>

          <div
            @click="openLanguage(language, 'r_edit-user-language')"
            style="cursor: pointer;"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
                >{{
                  displayLabelName("manage-users", "edit-user", "language")
                }}</label
              >
              <p class="text-label ellipsis" style="cursor: pointer;">
                {{ language ? language.name : "" }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </div>
          <Field
            name="language_id"
            as="input"
            type="hidden"
            rules="required"
            :disabled="submitting"
            v-model="language"
          >
          </Field>
        </li>
        <!---------------------------------->
        <!-- LANGUAGE - END -->
        <!---------------------------------->
        <!---------------------------------->
        <!-- TIMEZONE - START -->
        <!---------------------------------->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.timezone_id }"
          v-if="userFormFields.timezone"
        >
          <span class="error-message" v-if="errors.timezone_id">
            {{ errors.timezone_id }}
          </span>
          <div
            @click="
              canItem(user.data, 'update')
                ? openTimezone(timezone, 'r_edit-user-timezone')
                : ''
            "
            class="clebex-item-content-wrapper"
            style="cursor: pointer;"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
                >{{
                  displayLabelName("manage-users", "edit-user", "timezone")
                }}</label
              >
              <p class="text-label ellipsis" style="cursor: pointer;">
                {{
                  timezone
                    ? timezone.name
                        .replace(/ \*/g, ", ")
                        .replace("UTC ", "UTC+")
                    : ""
                }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item" style="cursor: pointer;">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </div>
          <Field
            name="timezone_id"
            as="input"
            type="hidden"
            rules="required"
            :disabled="submitting"
            v-model="timezone"
          >
          </Field>
        </li>
        <!---------------------------------->
        <!-- TIMEZONE - END -->
        <!---------------------------------->
        <!---------------------------------->
        <!-- COUNTRY - START -->
        <!---------------------------------->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.country_id }"
          v-if="userFormFields.country"
        >
          <span class="error-message" v-if="errors.country_id">
            {{ errors.country_id }}
          </span>
          <div
            @click="openCountry(country, 'r_edit-user-country')"
            style="cursor: pointer;"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
                >{{
                  displayLabelName("manage-users", "edit-user", "country")
                }}</label
              >
              <p class="text-label ellipsis" style="cursor: pointer;">
                {{ country ? country.name : "" }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </div>
          <Field
            name="country_id"
            as="input"
            type="hidden"
            rules="required"
            :disabled="submitting"
            v-model="country"
          >
          </Field>
        </li>
        <!---------------------------------->
        <!-- COUNTRY - END -->
        <!---------------------------------->
      </ul>
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.sso"
        >
          <Popper
            v-if="user.data.azure_external_id"
            hover
            class="popper-warning w-100"
            :content="displayLabelName('profile.profile.added-externally')"
          >
            <section class="disabled">
              <div class="clebex-item-content-wrapper">
                <dl class="clebex-item-dl justify-start">
                  <dt class="clebex-item-dt">
                    <div class="checkbox slide">
                      <input
                        id="userSsoCheckbox"
                        type="checkbox"
                        v-model="hasSso"
                        :disabled="
                          submitting ||
                            !canItem(user.data, 'update') ||
                            user.data.sso
                        "
                      />
                      <label for="userSsoCheckbox"></label>
                    </div>
                  </dt>
                  <dd class="clebex-item-dd justify-start">
                    {{ displayLabelName("manage-users", "edit-user", "sso") }}
                  </dd>
                </dl>
              </div>
            </section>
          </Popper>
          <section v-else>
            <div class="clebex-item-content-wrapper">
              <dl class="clebex-item-dl justify-start">
                <dt class="clebex-item-dt">
                  <div class="checkbox slide">
                    <input
                      id="userSsoCheckbox"
                      type="checkbox"
                      v-model="hasSso"
                      :disabled="
                        submitting ||
                          !canItem(user.data, 'update') ||
                          user.data.sso
                      "
                    />
                    <label for="userSsoCheckbox"></label>
                  </div>
                </dt>
                <dd class="clebex-item-dd justify-start">
                  {{ displayLabelName("manage-users", "edit-user", "sso") }}
                </dd>
              </dl>
            </div>
          </section>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.visitor"
        >
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="userIsVisitorCheckbox"
                    type="checkbox"
                    v-model="isVisitor"
                    :disabled="
                      submitting ||
                        !canItem(user.data, 'update') ||
                        user.data.is_visitor
                    "
                  />
                  <label for="userIsVisitorCheckbox"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{
                  displayLabelName("manage-users", "edit-user", "is-visitor")
                }}
              </dd>
            </dl>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          v-if="userFormFields.addAssignableContainersToUser"
        >
          <router-link
            :to="{ name: 'r_edit-user-add-containers-to-user' }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedContainers && selectedContainers.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "manage-users",
                    "edit-user",
                    "add-assignable-containers-to-user"
                  )
                }}
              </div>
              <p class="text-label">
                {{ selectedContainers.map(item => `${item.name}`).join(", ") }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "manage-users",
                  "edit-user",
                  "add-assignable-containers-to-user"
                )
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
        <li
          class="clebex-item-section-item"
          v-if="userFormFields.assignResourcesToAUser"
        >
          <router-link
            :to="{ name: 'r_edit-user-add-resources-to-user' }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="resourcesAssigned && resourcesAssigned.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "manage-users",
                    "edit-user",
                    "assign-resources-to-user"
                  )
                }}
              </div>
              <p class="text-label">
                {{
                  resourcesAssigned
                    .map(item => `${item.resource.name}`)
                    .join(", ")
                }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "manage-users",
                  "edit-user",
                  "assign-resources-to-user"
                )
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
      </ul>
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="phoneId"
                >{{
                  displayLabelName("manage-users", "edit-user", "login-history")
                }}
                <span></span
              ></label>
              <div
                v-if="user.data.access_logs.length > 0"
                style="display: flex; flex-direction: column;"
              >
                <span
                  style="background-color: transparent; box-shadow: none; color: #999999; font-size: 1rem; font-weight: 400; height: 1.4375rem; letter-spacing: -0.02562rem; line-height: 1.375rem; padding: 0 1rem; width: 100%; font-family: Roboto;"
                  v-for="access_log in user.data.access_logs"
                  :key="access_log.id"
                >
                  {{ access_log.datetime }}</span
                >
              </div>
              <div v-else>
                <span
                  style="background-color: transparent; box-shadow: none; color: #999999; font-size: 1rem; font-weight: 400; height: 1.4375rem; letter-spacing: -0.02562rem; line-height: 1.375rem; padding: 0 1rem; width: 100%; font-family: Roboto;"
                  >{{
                    displayLabelName(
                      "manage-users",
                      "edit-user",
                      "user-did-not-yet-log-in"
                    )
                  }}</span
                >
              </div>
            </div>
          </div>
        </li>
      </ul>
      <button
        type="submit"
        ref="submitProfileForm"
        style="display: none"
      ></button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import MobileScreen from "@/layouts/MobileScreen";
import { apiEndpoints } from "@/services/constants";
import { mapActions, mapState, mapGetters } from "vuex";
import languagesMixin from "@/services/mixins/languages/languages-mixin";
import timezonesMixin from "@/services/mixins/timezones/timezones-mixin";
import countriesMixin from "@/services/mixins/countries/countries-mixin";
import { errorHandler } from "@/services/error-handler";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import Popper from "vue3-popper";

export default {
  name: "EditUser",
  data() {
    return {
      user: null,
      submitting: false,
      hasSso: false,
      isVisitor: false,
      editing: true
    };
  },
  created() {
    const userId = this.$route.params.user_id;
    if (userId) {
      this.getUserForEditing(userId);
    } else {
      this.$router.push({ name: "r_manage-users" });
    }
  },
  watch: {
    routeParam() {
      if (this.routeParam) {
        this.$store.commit("user/setUserResourcesAssigned", null, {
          root: true
        });
        this.getUserForEditing(this.routeParam);
      }
    },
    user: {
      immediate: true,
      handler() {
        if (this.user && this.user.data) {
          this.hasSso = Boolean(this.user.data.sso);
          this.isVisitor = Boolean(this.user.data.is_visitor);
        }
      }
    }
  },
  computed: {
    ...mapState("user", ["userId", "selectedContainers", "userFormFields"]),
    ...mapState("language", ["selectedLanguage"]),
    ...mapGetters("user", ["resourcesAssigned"]),
    ...mapState("country", ["selectedCountry"]),
    routeParam() {
      return this.$route.params && this.$route.params.user_id;
    },
    language() {
      if (this.selectedLanguage) {
        return this.selectedLanguage;
      } else {
        return this.getLanguage(this.user.data.language_id);
      }
    },
    timezone() {
      if (this.selectedTimezone) {
        return this.selectedTimezone;
      } else {
        return this.getTimezone(this.user.data.timezone_id);
      }
    },
    country() {
      if (this.selectedCountry) {
        return this.selectedCountry;
      } else {
        return this.getCountry(this.user.data.country_id);
      }
    }
  },
  methods: {
    ...mapActions("user", [
      "getMasterUserList",
      "getCompanyUserList",
      "selectUserForEditing",
      "getMasterUserList"
    ]),
    ...mapActions("country", ["setSelectedCountry"]),
    getUserForEditing(id) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .get(`${apiEndpoints.company.users}/${id}`, {
          params: {
            includes: ["assigned_containers", "assigned_resources"]
          }
        })
        .then(response => {
          this.user = response.data;
          const { data } = response;
          if (
            data &&
            data.data &&
            data.data.assigned_containers &&
            data.data.assigned_containers.length
          ) {
            this.$store.commit(
              "user/setSelectedContainers",
              data.data.assigned_containers,
              { root: true }
            );
          }

          if (!this.resourcesAssigned) {
            if (
              data &&
              data.data &&
              data.data.assigned_resources &&
              data.data.assigned_resources.length
            ) {
              this.$store.commit(
                "user/setUserResourcesAssigned",
                data.data.assigned_resources,
                { root: true }
              );
            } else {
              this.$store.commit("user/setUserResourcesAssigned", null, {
                root: true
              });
            }
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    submitForm(values, { resetForm }) {
      if (values) {
        const subdomain = getSubdomain();

        values.sso = Number(this.hasSso);
        values.is_visitor = Number(this.isVisitor);

        if (this.selectedContainers && this.selectedContainers.length) {
          values.container_ids = this.selectedContainers.map(item => item.id);
        }
        const data = {
          ...this.user.data,
          ...values
        };
        this.submitting = true;
        if (subdomain) {
          this.submitCompanyData(data, resetForm);
        } else {
          this.submitMasterData(data, resetForm);
        }
      }
    },
    submitMasterData(values, resetForm) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(`${apiEndpoints.master.users}/${this.user.data.id}`, values)
        .then(() => {
          resetForm();
          this.getMasterUserList();
          this.selectUserForEditing([
            {
              ...values
            }
          ]);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    submitCompanyData(values, resetForm) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(`${apiEndpoints.company.users}/${this.user.data.id}`, values)
        .then(() => {
          resetForm();
          this.getUserForEditing(this.user.data.id);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    initSubmit() {
      this.$refs.submitProfileForm.click();
    }
  },
  beforeUnmount() {
    this.$store.commit("user/setAssignableContainers", null, { root: true });
    this.$store.commit("user/setSelectedContainers", null, { root: true });
    this.$store.commit("user/setUserResourcesAssigned", null, { root: true });
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Popper
  },
  mixins: [languagesMixin, timezonesMixin, countriesMixin]
};
</script>
